<template>
  <div class="pageContol listWrap templateList formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">专题管理（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">专题列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a"
            >{{ stu != "edit" ? "新增" : "修改" }}专题</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="form-box">
              <el-form
                ref="ruleForm"
                label-width="7.5rem"
                class="form"
                :rules="rules"
                :model="ruleForm"
              >
                <el-form-item label="专题名称：" prop="specialSubjectName">
                  <el-input
                    v-model="ruleForm.specialSubjectName"
                    size="small"
                    maxlength="50"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="专题模板：" prop="templateType">
                  <el-radio-group v-model="ruleForm.templateType">
                    <el-radio
                      :label="item.value"
                      v-for="(item, index) in specialTemplateTypeList"
                      :key="index"
                      >{{ item.label }}
                       <el-image   style="width: 150px; " :src="item.value == '10'?require('@/assets/报名采集模版示例一.png'):require('@/assets/报名采集模版示例二.png')"
                       :preview-src-list="[item.value == '10'?require('@/assets/报名采集模版示例一.png'):require('@/assets/报名采集模版示例二.png')]" ></el-image>
                    </el-radio>
                    <!-- <el-image v-show="ruleForm.templateType == '10'"  style="width: 300px; " :src="require('@/assets/报名采集模版示例一.png')"
                       :preview-src-list="[require('@/assets/报名采集模版示例一.png')]" ></el-image>
                    <el-image v-show="ruleForm.templateType == '20'"  style="width: 300px; " :src="require('@/assets/报名采集模版示例二.png')"
                       :preview-src-list="[require('@/assets/报名采集模版示例二.png')]" ></el-image> -->
                  </el-radio-group>
                  
                </el-form-item>

                <el-form-item
                  label="专题详情："
                  prop="context"
                  class="form-item"
                >
                  <div
                    ref="editor"
                    class="editor"
                    style="width:100%;max-width:1200px"
                  />
                </el-form-item>
                <el-form-item label="专题课程：" prop="courseIds">
                  <el-select
                    size="small"
                    v-model="ruleForm.courseIds"
                    multiple
                    filterable
                    clearable
                    placeholder="请选择专题课程"
                  >
                    <el-option
                      v-for="(item, index) in SpecialCoursesList"
                      :key="index"
                      :label="item.courseName"
                      :value="item.courseId"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="宣传图片："
                  prop="thumbnail"
                  :class="[ruleForm.id ? 'item-disabled' : '']"
                >
                  <el-upload
                    :on-change="handlebusinessLicense2"
                    :before-upload="$beforeAvatarUpload"
                    :http-request="$requestMine"
                    :show-file-list="false"
                    class="img-el-upload"
                    action
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                      :src="
                        ruleForm.thumbnail || require('@/assets/develop.png')
                      "
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                  </el-upload>
                </el-form-item>
              </el-form>
            </div>
            <div class="btn-box flexcc">
              <el-button @click="dohandleOk" class="bgc-bv">取 消</el-button>
              <el-button class="bgc-bv" @click="doAddSave('ruleForm')"
                >保存
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <MenuStyle ref="MenuStyle" @eventBus="styleBack" /> -->
  </div>
</template>
<script>
import E from "wangeditor";
let editor = {};
export default {
  name: "operate/accountEdit",
  components: {},
  data() {
    return {
      stu: "add",
      // 基本信息
      ruleForm: {
        specialSubjectName: "",
        templateType: "", //模板类型
        context: "", // 富文本
        compId: "",
        courseIds: [],
        thumbnail: "",
        thumbnailId: "",
        specialSubjectId: "",
      },
      rules: {
        specialSubjectName: [
          { required: true, message: "请输入专题名称", trigger: "blur" },
        ],
        templateType: [
          { required: true, message: "请选择模板", trigger: "change" },
        ],
        courseIds: [
          { required: true, message: "请选择专题课程", trigger: "change" },
        ],
        thumbnail: [
          { required: true, message: "请上传宣传图片", trigger: "change" },
        ],
      },
      CompanyList: [],
      SpecialCoursesList: [], //专题课程
      specialTemplateTypeList: [],
    };
  },
  created() {
    this.stu = this.$route.query.stu;
    this.getSpecialCoursesList();
    this.getSpecialTemplateType();
    if (this.stu == "edit") {
      this.ruleForm.specialSubjectId = this.$route.query.specialSubjectId;
      this.getDetail(this.$route.query.specialSubjectId);
    }
  },
  mounted() {
    this.editorInit();
    const eWidth = this.$refs.editor.clientWidth;
    this.eWidth = eWidth;
  },
  computed: {},
  methods: {
    // 富文本
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = true;

      editor.config.onchange = (html) => {
        this.ruleForm.specialSubjectDetail = html;
      };
      editor.config.pasteTextHandle = function(content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    },

    /**
     * 新增
     */
    doAddSave(formName) {
      const params = {
        specialSubjectName: this.ruleForm.specialSubjectName,
        specialSubjectDetail: this.ruleForm.specialSubjectDetail,
        templateType: this.ruleForm.templateType,
        courseIds: this.ruleForm.courseIds,
        publicityKey: this.ruleForm.thumbnailId,
      };
      if (this.stu == "edit") {
        params.specialSubjectId = this.ruleForm.specialSubjectId;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
            // if (this.ruleForm.specialSubjectDetail == '') {
            //     this.$message({
            //         message:'请输入专题详情',
            //         type:'error'
            //     })
            //     return
            // }
            if (editor.txt.text().trim() == "") {
          this.$message.warning("请输入专题详情");
          return;
        }
          this.$post(
            this.stu != "edit"
              ? "/miniapp/special/subject/comp/insert"
              : "/miniapp/special/subject/comp/update",
            params,
            3000,
            true,
            2
          )
            .then((res) => {
              if (res.status == "0") {
                if (this.stu != "edit") {
                  this.$message({
                    type: "success",
                    message: "新增成功",
                  });
                } else {
                  this.$message({
                    type: "success",
                    message: "修改成功",
                  });
                }
                this.dohandleOk();
              }
            })
            .catch(() => {
              return;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    /**
     * 编辑回显
     */
    getDetail(specialSubjectId) {
      this.$post(
        "/miniapp/special/subject/comp/getInfo",
        { specialSubjectId },
        3000,
        true,
        2
      ).then((ret) => {
        this.ruleForm.specialSubjectName = ret.data.specialSubjectName;
        this.ruleForm.templateType = ret.data.templateType;
        this.ruleForm.courseIds = ret.data.courseIds.courseIds;
        this.ruleForm.thumbnailId = ret.data.publicityKey;
        this.ruleForm.thumbnail = ret.data.publicityKeyUrl;
        console.log(this.ruleForm);
        editor.txt.html(ret.data.specialSubjectDetail);
        // this.getCompany(ret.data.compId);
      });
    },
    // 专题课程
    getSpecialCoursesList() {
      this.$post("/miniapp/special/subject/coursePageList", {}, 3000, true, 2)
        .then((res) => {
          if (res.status == 0) {
            this.SpecialCoursesList = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    // 宣传图片
    handlebusinessLicense2(res) {
      this.uploadFetch(res.raw);
    },
    uploadFetch(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.ruleForm.thumbnail = ret.data.fileURL || "";
        that.ruleForm.thumbnailId = ret.data.fileKey || "";
      });
    },
    /**
     * 返回
     */
    dohandleOk() {
      setTimeout(() => {
        this.$router.push({
          path: "/web/miniApp/thematicListJg",
          query: {
            refresh: true,
          },
        });
      }, 300);
    },
    //获取模版
    getSpecialTemplateType() {
      const specialTemplateType =
        this.$setDictionary("SPECIAL_TEMPLATE_TYPE", "list") || [];
      let list = [];
      for (const key in specialTemplateType) {
        list.push({
          value: key,
          label: specialTemplateType[key],
        });
      }
      this.specialTemplateTypeList = list;
    },
  },
};
</script>
<style lang="less" scoped>
.andDiscountPrice {
  /deep/.el-form-item__content {
    margin-left: 0 !important;
  }
}
.price {
  display: flex;
  //   width: 45%;
  div {
    display: flex;
    span {
      text-align: right;
      margin-right: 0.25rem;
    }
  }
}
.createdCourse {
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 240px;
    font-size: 14px;
  }
  .level {
    padding: 3px;
    border-radius: 6px;
    color: #333;
    background-color: #e0f2ff;
    min-width: 20px;
    text-align: center;
  }
}

.formCom {
  h3 {
    padding: 15px 0;
  }
  .el-form-item {
    margin-bottom: 22px;
  }
  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .el-progress {
    width: 200px;
    padding-left: 15px;
  }
  .chapterl-trees {
    padding: 15px 10px 15px 0;
    .el-tree-node__content {
      height: 36px;
      margin-bottom: 15px;
    }
    .draggable-item {
      margin-left: 10px;
      em {
        margin-right: 5px;
      }
    }

    .trees-btns {
      display: flex;
      align-items: center;
      .showbtn {
        color: #fff;
      }
      margin-left: 15px;
      & > em,
      .handle > em {
        color: #4089fa;
        font-size: 14px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .chapteru-lists {
    padding: 15px 15px 15px 0;
    .el-divider--horizontal {
      margin: 5px;
    }
    .cl-subhint {
      display: flex;
      span {
        font-size: 14px;
        color: #666;
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
      em {
        margin: 0 5px;
      }
    }
    .cl-btns {
      display: flex;
      align-items: center;
      width: 4rem;
      justify-content: space-between;
      i {
        color: #999999;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .el-icon-receiving {
    width: 42px;
    height: 42px;
    font-size: 40px;
    color: #999;
    margin-right: 15px;
  }
  .pageForm-btns {
    padding: 15px 0;
    text-align: center;
    .el-button {
      width: 10rem;
      span {
        min-width: 4em;
      }
    }
  }
  .file-list-group {
    .list-group-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }
  .tree-drag-group {
    display: flex;
    align-items: center;
    font-size: 14px;
    .list-group-item {
      margin-left: 5px;
      display: inline-block;
      .el-icon-receiving {
        font-size: 22px;
        color: #2d2;
      }
      .df {
        display: none;
      }
    }
  }
  .bgc-height {
    background-color: #f4ff00;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 148px !important;
    height: 148px !important;
  }
  .hide /deep/.el-upload--picture-card {
    display: none;
  }
}

.btnbox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.courseDescription {
  /deep/.el-textarea__inner {
    min-height: 120px !important;
    resize: none;
  }
}
</style>
<style lang="less" scoped>
.el-textarea {
  .el-textarea__inner {
    min-height: 10px !important;
    height: 100px !important;
    resize: none;
  }
}

.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}

.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}

.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
</style>
